import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

// eslint-disable-next-line react-refresh/only-export-components
const PdfViewer = lazy(() => import('./ui'))

export const PdfViewerRoutes: RouteObject[] = [
  {
    path: routePaths.privacyPolicy,
    element: (
      <Suspense>
        <PdfViewer doc={'privacyPolicy'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.termsAndConditions,
    element: (
      <Suspense>
        <PdfViewer doc={'termsAndConditions'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.integratedManagementPolicy,
    element: (
      <Suspense>
        <PdfViewer doc={'integratedManagementPolicy'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.rulesOfInvestigationOfComplains,
    element: (
      <Suspense>
        <PdfViewer doc={'rulesOfInvest'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.arbiexKYCPolicy,
    element: (
      <Suspense>
        <PdfViewer doc={'KYCPolicyNewest'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.arbiexPrivacyPolicy,
    element: (
      <Suspense>
        <PdfViewer doc={'privacyPolicyNewest'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.arbiexServiceAgreement,
    element: (
      <Suspense>
        <PdfViewer doc={'serviceAgreementChoose'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.accountFees,
    element: (
      <Suspense>
        <PdfViewer doc={'accountFees'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.accountFeesNew,
    element: (
      <Suspense>
        <PdfViewer doc={'accountFeesNew'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.cardFees,
    element: (
      <Suspense>
        <PdfViewer doc={'cardFees'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.feeSpecialHRG,
    element: (
      <Suspense>
        <PdfViewer doc={'feeSpecialHRG'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.feeSpecialCACO,
    element: (
      <Suspense>
        <PdfViewer doc={'feeSpecialCACO'} />
      </Suspense>
    ),
  },
  {
    path: routePaths.feeSpecialUKR,
    element: (
      <Suspense>
        <PdfViewer doc={'feeSpecialUKR'} />
      </Suspense>
    ),
  },

  // TODO is this routes needed?
  {
    path: '/payment-services/transport/voltray/terms-and-conditions',
    element: (
      <Suspense>
        <PdfViewer doc={'voltrayTermsAndConditions'} />
      </Suspense>
    ),
  },
  {
    path: '/payment-services/transport/voltray/imprint',
    element: (
      <Suspense>
        <PdfViewer doc={'voltrayImprint'} />
      </Suspense>
    ),
  },
  {
    path: '/payment-services/transport/voltray/refund-policy',
    element: (
      <Suspense>
        <PdfViewer doc={'voltrayRefundPolicy'} />
      </Suspense>
    ),
  },
  {
    path: '/payment-services/transport/voltray/privacy-policy',
    element: (
      <Suspense>
        <PdfViewer doc={'voltrayPrivacyPolicy'} />
      </Suspense>
    ),
  },
]
