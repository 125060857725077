import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const ProhibitedCountriesPageRoutes: RouteObject[] = [
  {
    path: routePaths.prohibitedCountries,
    lazy: async () => {
      const { ProhibitedCountriesAndActivitiesPage } = await import('./ui')

      return { Component: ProhibitedCountriesAndActivitiesPage }
    },
  },
  {
    path: routePaths.prohibitedCountriesV2,
    lazy: async () => {
      const { ProhibitedCountriesAndActivitiesPage } = await import('./ui')

      return { Component: ProhibitedCountriesAndActivitiesPage }
    },
  },
]
