export const routePaths = {
  // Auth and registration
  phoneNumberVerification: '/registration/fast/phone-number-verification',
  emailVerification: '/registration/fast/email-verification',
  registrationFinish: '/registration/fast/finish',

  // Landing and info pages //
  mainPage: '/',
  contactsPage: '/contacts',
  businessAccountPage: '/business-account',
  personalAccountPage: '/personal-account',
  documentsInfoBusiness: '/documents-set-for-business',
  documentsInfoPersonal: '/documents-set-for-personal',
  prohibitedCountries: '/back',
  prohibitedCountriesV2: '/prohibited-countries-and-activities',
  feesPage: '/fees',
  openNewArbipayAccount: '/open-new-arbipay-account',

  //   PDF Documents
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  integratedManagementPolicy: '/integrated-management-policy',
  rulesOfInvestigationOfComplains: '/rules-of-investigation-of-complains',
  arbiexKYCPolicy: '/arbiex-kyc-policy',
  arbiexPrivacyPolicy: '/arbiex-privacy-policy',
  arbiexServiceAgreement: '/arbiex-service-agreement',
  accountFees: '/account-fees',
  accountFeesNew: '/account-fees-new',
  cardFees: '/card-fees',
  feeSpecialHRG: '/fee-special-hrg',
  feeSpecialCACO: '/fee-special-ca-co',
  feeSpecialUKR: '/fee-special-ukr',
}
