import { Box, Stack } from '@mui/material'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { appBarHeight } from '@shared/constants'

import { StyledMain } from './styled'

export function MainLayout() {
  return (
    <Fragment>
      <Helmet link={[{ rel: 'icon', href: '/favicon.ico' }]} />
      <ScrollRestoration />

      <Box>
        <AppBar />

        <Stack
          sx={{
            mt: { lg: appBarHeight.lg, xs: appBarHeight.xs },
            minHeight: {
              xs: `calc(100vh - ${appBarHeight.xs})`,
              lg: `calc(100vh - ${appBarHeight.lg})`,
            },
          }}
        >
          <StyledMain>
            <Outlet />
          </StyledMain>

          <Footer />
        </Stack>
      </Box>
    </Fragment>
  )
}
